<template>
  <el-card shadow="never" header="AI任务日志">

    <div class="m-b-15 flex h vc">
      <div style="width: 380px">
        <el-date-picker
          v-model="selectDate"
          type="daterange"
          range-separator="To"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
          :shortcuts="shortcuts"
        />
      </div>
      <el-input v-model="userId" class="m-l-15" clearable style="width: 100px" placeholder="用户id"></el-input>
      <el-input v-model="tmpSearchKWs" class="m-l-15" clearable style="width: 300px" placeholder="搜索"></el-input>
      <el-button type="primary" style="margin-left: 15px" @click="handleSearch">查询</el-button>
    </div>

    <PaginationView :current-page="currentPage" @current-change="handlePageChange"
                    :total="selectedCount"></PaginationView>
    <el-table :data="list">
      <el-table-column label="待解析文案" prop="content" width="200">
        <!--        style="white-space: pre-wrap;word-wrap: break-word;"-->
        <template #default="{row}">
          <div style="white-space: pre-wrap;word-wrap: break-word;" v-if="row.content">{{ row.content}}</div>
          <div  v-if="row.pic" >
            <a :href="row.pic" target="_blank"><img :src="row.pic" class="cover"></a>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="AI提示词" prop="tip" width="400"></el-table-column>
      <el-table-column label="AI解析结果" prop="result" width="200"></el-table-column>
      <el-table-column prop="userInfo.name" label="用户" width="200">
        <template #default="{row}">
          <router-link v-if="row.userInfo" :to="{name:'manage_user_detail', params:{id:row.userInfo.userId}}"
                       target="_blank">
            <div style="white-space: nowrap">{{ row.userInfo.name ? row.userInfo.name: '未知'}}#{{row.userId}}</div>
          </router-link>
          <div v-else></div>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" prop="created" width="100">
        <template #default="{row}">
          <div>{{ row.created }}</div>
        </template>
      </el-table-column>
    </el-table>
    <PaginationView :current-page="currentPage" @current-change="handlePageChange"
                    :total="selectedCount"></PaginationView>
  </el-card>
</template>

<script>
  import {aiActionRecordApi} from '@/api/ai';
  import {ElMessage, ElMessageBox} from 'element-plus';
  import moment from 'moment/moment';
  import utils from '@/util/utils';

  export default {
    name: 'SettingListScreen',
    data() {
      return {
        showForm: false,
        form: null,
        list: [],
        currentPage: 1,
        selectedCount: 0,
        accounts: [],
        products: [],
        statusArr: [
          {
            val: 0,
            label: '未使用'
          },
          {
            val: 1,
            label: '已使用'
          }
        ],
        selectDate: null,
        userId: null,
        tmpSearchKWs: null,
        shortcuts: [
          {
            text: '今天',
            value: () => {
              const today = moment().format('YYYYMMDD');
              return [today, today];
            }
          },
          {
            text: '昨天',
            value: () => {
              const tomorrow = moment().subtract(1, 'day').format('YYYYMMDD');
              return [tomorrow, tomorrow];
            }
          },
          {
            text: '近7天',
            value: () => {
              const start = moment().subtract(6, 'day').format('YYYYMMDD');
              const today = moment().format('YYYYMMDD');
              return [start, today];
            }
          },
          {
            text: '近30天',
            value: () => {
              const start = moment().subtract(29, 'day').format('YYYYMMDD');
              const today = moment().format('YYYYMMDD');
              return [start, today];
            }
          },
          {
            text: '本月',
            value: () => {
              let start = moment().date(1);
              start = start.format('YYYYMMDD');
              const today = moment().format('YYYYMMDD');
              return [start, today];
            }
          },
          {
            text: '本周',
            value: () => {
              let start = moment().day(1);
              start = start.format('YYYYMMDD');
              const today = moment().format('YYYYMMDD');
              return [start, today];
            }
          }
        ],
      };
    },
    watch: {
      '$route.params.id'(id) {
        if (!id) {
          return;
        }
        this.init();
      }
    },
    async created() {
      const selectDate = this.$route.query.date;
      const today = (selectDate ? moment(selectDate) : moment()).format('YYYY-MM-DD');
      this.selectDate = [today, today];
      this.init();
    },
    methods: {
      statusFormat(row) {
        return this.statusArr.find(item => item.val === row.status).label;
      },
      async init() {
        const res2 = await aiActionRecordApi.list({
          index: this.currentPage - 1,
          limit: 20,
          key__regex: this.tmpSearchKWs,
          start: this.selectDate ? this.selectDate[0] : null,
          end: this.selectDate ? this.selectDate[1] : null,
          userId: this.userId
        });
        this.list = res2.data.data.list;
        this.selectedCount = res2.data.data.count;
      },
      async copy(url) {
        await utils.copyToClipboard(url);
        ElMessage({
          type: 'success',
          message: '复制成功'
        });
      },
      dateFormat(time) {
        if (time) {
          return moment(time).format('YYYY-MM-DD HH:mm:ss');
        }
        return '';
      },
      handlePageChange(page) {
        this.currentPage = page;
        this.init();
      },
      handleSearch() {
        this.currentPage = 1;
        this.init();
      },
    }
  };
</script>

<style scoped lang="less">
  .cover {
    width: 135px;
    height: 250px;
  }
</style>
